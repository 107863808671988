// adds polyfills for ie11 compatibility
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'jquery';
import '@fancyapps/fancybox';
import ScrollReveal from 'scrollreveal';
import 'single-page-nav';
import { ChatBotUiLoader } from '../../lex-web-ui-loader/js';
// Styling
import "../css/website.scss";

ScrollReveal().reveal('[data-sr]');
window.ChatBotUiLoader = ChatBotUiLoader;

$(document).ready(function() {

    // =Hero
    // Alway make hero-container height equal to window height

    var $heroContainer = $('.hero');

    $heroContainer.height(window.innerHeight);

    // When user resize browser window, hero container needs to have the same
    // height as browser window height.

    $(window).resize(function() {
        $heroContainer.height(window.innerHeight);
    });

    // =services
    // Isotope filters

    var $servicesFilterLinks = $('.services-filters li'),
        $container = $('.services-items');

    $servicesFilterLinks.find('a').click(function() {

        $servicesFilterLinks.removeClass('active');

        $container.isotope({
            // options
            filter: $(this).attr('data-filter'),
            itemSelector: '.isotope-item',
            animationEngine: "best-available",
            masonry: {
                columnWidth: '.isotope-item'
            }
        });

        $(this).parent().addClass('active');

        return false;
    });

    // Menu initialization

    var $menuIcon = $('.menu-icon'),
        $navigation = $('.navigation'),
        $mainNavigation = $('.main-navigation'),
        $navigationLink = $('.main-navigation a');

    $(window).scroll(function() {
        if (window.scrollY > window.outerHeight) {
            $menuIcon.addClass('active');
        } else {
            $menuIcon.removeClass('active');
        }
    });

    $menuIcon.click(function(e) {
        e.preventDefault();

        $navigation.toggleClass('active');
    });

    $mainNavigation.singlePageNav({
        filter: ':not(.external)',
        speed: 1000,
        currentClass: 'current',
        easing: 'swing',
        updateHash: false,
        beforeStart: function() {},
        onComplete: function() {
            $navigation.removeClass('active');
        }
    });
    // Fancybox

    $(".video-play").fancybox({
        fitToView: true,
        width: '100%',
        autoSize: true,
        closeClick: false,
        openEffect: 'none',
        closeEffect: 'none'
    });
});

// Typewriter effect

var TxtType = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function() {
        that.tick();
    }, delta);
};

window.onload = function() {
    var elements = document.getElementsByClassName('typewrite');
    for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
};
